import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import { shades } from "../../theme";

function Footer() {
  const {
    palette: { neutral },
  } = useTheme();
  return (
    <Box marginTop="70px" padding="40px 0" backgroundColor={neutral.light}>
      <Box
        width="80%"
        margin="auto"
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        rowGap="30px"
        columnGap="clamp(20px, 30px, 40px)"
      >
        <Box width="clamp(20%, 30%, 40%)">
          <Typography
            variant="h4"
            fontWeight="bold"
            mb="30px"
            color={shades.secondary[500]}
          >
            DELUXRAY
          </Typography>
          <div>
          Deluxray prides itself on its investment into manufacturing and technology. The same characteristics that set us apart then are the reason we are one of the nation's premier custom apparel providers now: an emphasis on customer service, quality, and convenience. We hope that continuing to concentrate on these things will take us to the next level in the future.
          </div>
        </Box>

        <Box>
          <Typography variant="h4" fontWeight="bold" mb="30px">
            About Us
          </Typography>
          <Typography mb="30px">Careers</Typography>
          <Typography mb="30px">Our Stores</Typography>
          <Typography mb="30px">Terms & Conditions</Typography>
          <Typography mb="30px">Privacy Policy</Typography>
        </Box>

        <Box>
          <Typography variant="h4" fontWeight="bold" mb="30px">
            Customer Care
          </Typography>
          <Typography mb="30px">Help Center</Typography>
          <Typography mb="30px">Track Your Order</Typography>
          <Typography mb="30px">Corporate & Bulk Purchasing</Typography>
          <Typography mb="30px">Returns & Refunds</Typography>
        </Box>

        <Box width="clamp(20%, 25%, 30%)">
          <Typography variant="h4" fontWeight="bold" mb="30px">
            Contact Us
          </Typography>
          <Typography mb="30px">
            Freeport, NY 11520
          </Typography>
          <Typography mb="30px" sx={{ wordWrap: "break-word" }}>
            Email: admin@deluxray.com
          </Typography>
          <Typography mb="30px">(908) 791-5658</Typography>
        </Box>

      </Box>


        <Box width="100%" height="20px" alignItems="center">
        <h4 align="center">Copyright ©{new Date().getFullYear()} Deluxray</h4>
        </Box>

    </Box>
  );
}

export default Footer;